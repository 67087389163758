import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { respondTo } from "../_respondTo"

import { FiArrowDownCircle } from "react-icons/fi"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/section"

import BackgroundSlider from "gatsby-image-background-slider"

import scrollTo from "gatsby-plugin-smoothscroll"

import TwmButton from "../components/twm-button"
import { palette } from "../_variables"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
`

const WrapperBottom = styled.div`
  width: 100%;
  position: relative;
  left: 0;
  top: 0;
  background-color: ${palette.dark};
  color: ${palette.darkContrast};

  h2 {
    color: white;
  }

  a {
    color: white;
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
`

const StrapLine = styled.div`
  top: 20vh;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 1em;
  text-align: center;
  color: white;
  position: absolute;
  max-width: 960px;

  h1 {
    text-transform: uppercase;
  }

  ${respondTo.sm`
    top: 40vh;
  `}
`

const ScrollButton = styled.a`
  color: white;
  font-size: 30px;
  border-radius: 30px;
  position: absolute;
  bottom: 5%;

  ${respondTo.md`
  font-size: 40px;
  border-radius: 40px;

`}

  :hover {
    background-color: ${palette.primary};
  }

  transition: background-color 1s ease;
`

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;

  ${respondTo.md`
    flex-direction: row;

`}
`

const FlexItem = styled.div`
  margin-top: 7vh;
  flex: 1 1 0px;
  margin: 2em;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
`

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      keywords={[
        `Cutter & Co Planning`,
        `Financial Planning`,
        `Wealth Management`,
        `Financial Advice`,
        `Pensions`,
        `Investments`,
        `Financial Protection`,
      ]}
    />
    <Wrapper>
      <BackgroundSlider
        query={useStaticQuery(graphql`
          query {
            backgrounds: allFile(
              filter: { sourceInstanceName: { eq: "backgrounds" } }
            ) {
              nodes {
                relativePath
                childImageSharp {
                  fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        `)}
        initDelay={2} // delay before the first transition (if left at 0, the first image will be skipped initially)
        transition={2} // transition duration between images
        duration={8} // how long an image is shown
        // specify images to include (and their order) according to `relativePath`
        images={["mountain-climb.jpg", "lighthouse.jpg", "path.jpg"]}
        alt=""
        // pass down standard element props
      >
        {/* Captions in sync with background images*/}

        <StrapLine>
          <h1>Your goals. Your path.</h1>
          Everyone is different and that should be embraced. Your goals will be
          unique to you and therefore so should your plan be. It is essential
          that we provide a bespoke service for you and your family. You talk,
          we listen, then act accordingly.
        </StrapLine>
        <StrapLine>
          <h1>Helping You Make Smart Financial Choices</h1>
          Dealing with your finances can be a burden and can often feel overly
          complex. We have years of expertise in this area to keep you on track
          and achieve your financial goals. That way you can concentrate on
          enjoying life and doing the things that make you happy.
        </StrapLine>

        <StrapLine>
          <h1>Building a brighter future, together</h1>
          We will work with you in order to achieve your goals. We take the time
          to understand what is important to you, what your plans for the future
          are and ensure you have enough to make it happen. Life can throw many
          obstacles in the way, we aim to clear the path and act as your guide.
        </StrapLine>
      </BackgroundSlider>
      <ScrollButton onClick={() => scrollTo("#first")}>
        <FiArrowDownCircle></FiArrowDownCircle>
      </ScrollButton>
    </Wrapper>

    <div style={{ height: "100vh" }}></div>

    <WrapperBottom id="first">
      <Section type="wide" color="dark">
        <FlexBox>
          <FlexItem>
            <h2 id={"TWM"}>About you</h2>
            <p>
              It's all about you. Well, you and your loved ones. Your family's
              financial futures are woven together although changes will occur
              throughout your lives. We will take time to really understand
              these intricacies, to ensure your plan matches your
              requirements...
            </p>
            <div style={{ marginTop: "auto" }}>
              <Link to="/about-you">Read more...</Link>
            </div>
          </FlexItem>
          <FlexItem>
            <h2>What we do</h2>
            <p>
              Wealth Planning - helping you achieve the things that are most
              important to you without jeopardising your financial security. We
              will help you establish your goals and plan with you how you can
              achieve this by striking the right balance between what you
              spend...
            </p>
            <div style={{ marginTop: "auto" }}>
              <Link to="/what-we-do">Read more...</Link>
            </div>
          </FlexItem>
          <FlexItem>
            <h2>Contact us</h2>
            <p>
              To arrange an initial meeting without cost or obligation please
              get in touch
            </p>
            <p>
              Tel: 0345 3073637
              <br />
              Email: info@cutterandco-fp.co.uk
            </p>
            <div style={{ marginTop: "auto" }}>
              <Link to="/contact-us">Complete our contact form</Link>
            </div>
          </FlexItem>
        </FlexBox>
      </Section>
    </WrapperBottom>
  </Layout>
)

export default IndexPage
